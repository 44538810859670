@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbar-height: 64px; /* Adjust the height as per your navbar's actual height */
}



body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Global table styles */
figure.table {
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
  border-collapse: collapse;
}

figure.table table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  background-color: #f9f9f9;
}

figure.table th, figure.table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

figure.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

figure.table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

figure.table tbody tr:nth-child(even) {
  background-color: #fff;
}

figure.table tbody tr:hover {
  background-color: #f1f1f1;
}

figure.table caption {
  caption-side: top;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  figure.table th, figure.table td {
    padding: 8px;
  }
}


@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: bounce 0.6s infinite;
}

.animate-bounce200 {
  animation: bounce 0.6s infinite 0.2s;
}

.animate-bounce400 {
  animation: bounce 0.6s infinite 0.4s;
}


  